<template>
  <div class="mod-keywords">
    <el-form
      :inline="true"
      :model="searchForm"
      @keyup.enter.native="getDataList()"
    >
      <el-form-item>
        <el-input v-model="searchForm.keyword" placeholder="关键词" clearable />
      </el-form-item>
      <el-form-item>
        <el-button @click="getDataList()"> 查询 </el-button>
        <el-button
          v-if="isAuth('mall:keywords:save')"
          type="primary"
          @click="addOrUpdateHandle()"
        >
          新增
        </el-button>
        <el-button
          v-if="isAuth('mall:keywords:delete')"
          type="danger"
          :disabled="dataListSelections.length <= 0"
          @click="deleteHandle()"
        >
          批量删除
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      style="width: 100%"
      @selection-change="selectionChangeHandle"
    >
      <el-table-column
        type="selection"
        header-align="center"
        align="center"
        width="50"
      />
      <el-table-column
        prop="keyword"
        header-align="center"
        align="center"
        label="关键词"
      />
      <el-table-column
        prop="type"
        header-align="center"
        align="center"
        label="场景"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.type === 0" size="small" type="success">
            小程序
          </el-tag>
          <el-tag v-else-if="scope.row.type === 1" size="small" type="danger">
            公众号
          </el-tag>
          <el-tag v-else-if="scope.row.type === 2" size="small"> APP </el-tag>
          <el-tag v-else-if="scope.row.type === 3" size="small" type="info">
            PC
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="isHot"
        header-align="center"
        align="center"
        label="热搜"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.isHot === 0" size="small" type="danger">
            否
          </el-tag>
          <el-tag v-else-if="scope.row.isHot === 1" size="small" type="success">
            是
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="isDefault"
        header-align="center"
        align="center"
        label="默认"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.isDefault === 0" size="small" type="danger">
            否
          </el-tag>
          <el-tag
            v-else-if="scope.row.isDefault === 1"
            size="small"
            type="success"
          >
            是
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="isShow"
        header-align="center"
        align="center"
        label="显示"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.isShow === 0" size="small" type="danger">
            否
          </el-tag>
          <el-tag
            v-else-if="scope.row.isShow === 1"
            size="small"
            type="success"
          >
            是
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="schemeUrl"
        header-align="center"
        align="center"
        label="关键词的跳转链接"
      />
      <el-table-column
        prop="sort"
        header-align="center"
        align="center"
        label="排序"
      />
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            v-if="isAuth('mall:keywords:info')"
            type="text"
            size="small"
            @click="showDetails(scope.row.id)"
          >
            查看
          </el-button>
          <el-button
            v-if="isAuth('mall:keywords:update')"
            type="text"
            size="small"
            @click="addOrUpdateHandle(scope.row.id)"
          >
            修改
          </el-button>
          <el-button
            v-if="isAuth('mall:keywords:delete')"
            type="text"
            size="small"
            @click="deleteHandle(scope.row.id)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
    />
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    />
  </div>
</template>

<script>
import AddOrUpdate from './keywords-add-or-update';

export default {
  components: {
    AddOrUpdate,
  },
  data() {
    return {
      searchForm: {
        keyword: '',
      },
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListSelections: [],
      addOrUpdateVisible: false,
    };
  },
  activated() {
    this.getDataList();
  },
  methods: {
    // 获取数据列表
    getDataList() {
      this.$http({
        url: '/mall/keywords/list',
        method: 'get',
        params: {
          page: this.pageIndex,
          limit: this.pageSize,
          keyword: this.searchForm.keyword,
        },
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.page.records;
          this.totalPage = data.page.total;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    // 查看详情
    showDetails(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id, true);
      });
    },
    // 新增 / 修改
    addOrUpdateHandle(id) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id);
      });
    },
    // 删除
    deleteHandle(id) {
      let ids = id
        ? [id]
        : this.dataListSelections.map((item) => {
            return item.id;
          });
      this.$confirm(`确定对[id=${ids.join(',')}]进行[删除]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$http({
            url: '/mall/keywords/delete',
            method: 'post',
            data: ids,
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.getDataList();
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>
